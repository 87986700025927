import { ConfirmationResponse } from '@/features/register/types'
import { fetchServer } from '@/features/utils/fetch'
import { AddressCreate, CityInfoType } from '../../types'
import { AddressConverters } from '../converters'
import config from '@/config'

export class AddressApiClient {
  static async create(userUUID: string, address: AddressCreate): Promise<ConfirmationResponse> {
    const response = await fetchServer('/user/address/create_new', {
      method: 'POST',
      reloadOn401: false,
      mainRoot: false,
      body: AddressConverters.Create_FullDTO(userUUID, address)
    })

    if (!response.ok) throw new Error()

    const res = await response.json()
    return res.data || { existStatus: false }
  }

  static async getCityCode(city: string): Promise<CityInfoType[]> {
    const response = await fetch(`${config.URL_GOV_FR}communes?nom=${city}&fields=departement`)
    if (!response.ok) throw new Error()

    const res = await response.json()
    return res
  }
}
